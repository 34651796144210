import { Routes } from '@angular/router';
import { crmAuthGuard } from 'common-module/auth';
import { crmLayoutOutletRoute } from 'common-module/layout-v2';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('common-module/auth').then((m) => m.authRoutes),
  },
  crmLayoutOutletRoute({
    canActivate: [crmAuthGuard()],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./features/users/users.routes'),
      },
      {
        path: '**',
        redirectTo: 'users',
      },
    ],
  }),
];
