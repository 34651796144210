import { Component } from '@angular/core';
import { CrmAppComponent } from 'common-module/core';

@Component({
  standalone: true,
  selector: 'app-root',
  template: ` <crm-app></crm-app>`,
  imports: [CrmAppComponent],
})
export class AppComponent {}
