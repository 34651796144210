import { CrmAuthConfig, CrmAuthRouteKey } from 'common-module/auth';

export const authConfig: CrmAuthConfig = {
  routeGuard: {
    disabledRoutes: [
      CrmAuthRouteKey.forgottenPassword,
      CrmAuthRouteKey.resetPassword,
    ],
  },
  components: { login: { showForgottenPassword: false } },
} as const;
