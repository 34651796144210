import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CrmAuthModule } from 'common-module/auth';
import { CrmAppConfig } from 'common-module/core';
import { CrmEndpointModule } from 'common-module/endpoint';
import { CrmModalModule } from 'common-module/modal';
import { CrmTranslateModule } from 'common-module/translate';
import { CrmUserService } from 'common-module/user';

import { UserService } from '~/shared/crm/user/user.service';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { routes } from './app/app.routes';
import { authConfig } from './app/config/auth.config';
import { endpointConfig } from './app/config/endpoint.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      RouterModule.forRoot(routes),

      CrmAuthModule.forRoot(authConfig),
      CrmEndpointModule.forRoot(endpointConfig),
      CrmTranslateModule.forRoot(),
      CrmModalModule.forRoot(),
    ),
    { provide: CrmAppConfig, useValue: appConfig },
    UserService,
    { provide: CrmUserService, useExisting: UserService },
  ],
}).catch((err) => console.error(err));
